.header {
  background-image: url("../imgs/pelicula-dark.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70px;

  @media screen and (max-width: 1024px) {
    background-image: url("../imgs/pelicula-dark-mobile.jpeg");
    height: 650px;
  }

  nav {
    width: 100%;
    padding: 14px 120px;
    color: whitesmoke;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: 1024px) {
      padding: 16px;
    }

    @media screen and (max-width: 600px) {
      /*justify-content: center;*/
    }

    figure {
      display: flex;
      align-items: center;

      img {
        width: 180px;

        @media screen and (max-width: 1024px) {
          width: 140px;
        }
      }
    }

    ul {
      display: flex;
      gap: 30px;

      @media screen and (max-width: 1024px) {
        display: none;
      }

      li {
        padding: 5px 0;

        &:hover {
          border-bottom: 3px solid var(--orange);
          padding-bottom: 2px;
        }
      }
    }
  }

  &__texts {
    width: 50%;
    margin: 180px 120px 100px;

    @media screen and (max-width: 1024px) {
      margin: 100px 0 20px;
      padding: 20px;
      width: 100%;
    }

    &__fechado {
      width: max-content;
      margin-bottom: 20px;
      padding: 15px;
      background-color: white;
      color: red;
      font-size: 18px;
    }

    h1 {
      color: white;
      font-size: 54px;

      @media screen and (max-width: 1024px) {
        font-size: 30px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }

    h2 {
      color: var(--orange);
      text-transform: uppercase;

      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }

    &__terceiro {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    a {
      display: block;
      border: 1px solid whitesmoke;
      width: max-content;
      border-radius: 4px;
      padding: 5px 10px;
      margin-top: 10px;
      font-size: 22px;

      &:hover {
        background-color: whitesmoke;
        color: black;
      }

      @media screen and (max-width: 1024px) {
        margin-top: 20px;
      }
    }
  }
}
