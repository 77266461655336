.section {
  background-color: var(--orange);
  padding: 40px 120px;

  @media screen and (max-width: 1024px) {
    padding: 16px;
  }
}

.container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  padding: 30px;

  p {
    color: var(--black-100);
  }

  &__textos {
    p {
      margin-bottom: 5px;
    }

    ul {
      margin: 20px;
      list-style: circle;
    }
  }

  h3 {
    color: var(--orange);
    font-size: 26px;
    margin-bottom: 10px;
  }

  a {
    color: var(--orange);
  }

  &__links {
    display: flex;
    gap: 30px;
    margin-top: 20px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    a {
      color: gray;
    }

    li {
      color: var(--black-100);
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 20px;
    }

    img {
      width: 30px;
    }
  }
}
