main {
  .resumo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px 120px 0;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 10px;

    @media screen and (max-width: 1024px) {
      margin: 30px 16px 10px;
      padding: 8px;
    }

    h3 {
      color: var(--gray-200);
      text-transform: uppercase;
      font-weight: 500;

      @media screen and (max-width: 1024px) {
        font-size: 18px;
        text-align: center;
      }
    }
    span {
      font-size: 40px;
      font-weight: 600;

      @media screen and (max-width: 1024px) {
        font-size: 24px;
      }
    }
    p {
      color: var(--orange);
      font-size: 30px;
      font-weight: 500;
      @media screen and (max-width: 1024px) {
        font-size: 24px;
      }
    }
    &__superfilm {
      display: flex;
      gap: 5px;

      img {
        width: 30px;
        height: 30px;
        margin-top: 3px;

        @media screen and (max-width: 1024px) {
          margin-top: 0;
        }
      }
    }
  }

  .sobre {
    display: flex;
    gap: 30px;
    padding: 20px 120px;

    @media screen and (max-width: 1024px) {
      padding: 16px;
      flex-direction: column-reverse;
    }

    &__texts {
      width: 50%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      h3 {
        color: var(--gray-200);
        font-size: 28px;
        margin-bottom: 5px;
      }

      h4 {
        color: var(--orange);
        margin-bottom: 10px;
        font-weight: 500;
      }

      p {
        margin-bottom: 20px;
        color: var(--black-100);
      }
    }

    figure {
      width: 45%;
      margin-top: 50px;

      @media screen and (max-width: 1024px) {
        width: 100%;
        margin: 20px 0;
        display: flex;
        justify-content: center;
      }

      img {
        border-radius: 30px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.7);
        width: 100%;

        @media screen and (max-width: 1024px) {
          width: 95%;
          margin: 0;
        }
      }
    }
  }

  .duvidas {
    padding: 40px 120px;
    @media screen and (max-width: 1024px) {
      padding: 16px;
    }

    p {
      color: var(--black-100);
    }

    &__container {
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
      background-color: whitesmoke;
      border-radius: 18px;
      padding: 20px 16px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      h3 {
        color: var(--orange);
        font-size: 24px;
      }

      &__opcoes {
        display: flex;
        gap: 20px;
        flex-direction: column;
        margin-top: 20px;

        &__cima,
        &__baixo {
          display: flex;
          gap: 20px;
          justify-content: center;

          @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
          }

          div {
            border-radius: 20px;
            padding: 10px 10px 14px;
            width: 30%;
            text-align: center;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

            @media screen and (max-width: 1024px) {
              width: 100%;
            }

            p {
              font-size: 15px;
            }

            h5 {
              color: var(--orange);
              font-size: 20px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
