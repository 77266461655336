@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

:root {
  --font-roboto: "Roboto", sans-serif;
  --font-open: "Open Sans", sans-serif;

  --black: #000;
  --black-100: #292929;

  --black-300: #100d0e;

  --white: #fff;

  --gray: #303030;
  --gray-100: #c4c4c4;
  --gray-200: #888686;
  --gray-300: #e5e5e5;
  --gray-400: #b9b7b7;
  --gray-500: #f2f2f2;
  --gray-600: #c6c6c6;

  --red-100: #ff0000;

  --orange: #f87032;

  --green-100: #008000;
}
